import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_model'

const API_URL = process.env.REACT_APP_API_URL
const USER_DETAIL = `${API_URL}/user/detail`
const USER_DETAIL_AVATAR = `${API_URL}/user/upload-profile-pic`

const getUserDetail = (id: any): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_DETAIL}/${id}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const updateUserDetail = (payload: any): Promise<any> => {
  return axios
    .post(`${USER_DETAIL}/${payload.id}`, {
      ...payload,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUserAvatar = (payload: any, id:any): Promise<any> => {
  return axios
    .post(`${USER_DETAIL_AVATAR}/${id}`, payload, {headers: { "Content-Type": "multipart/form-data" }})
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getClickUpAuthUrl = (): Promise<string> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clickup/connect`).then((d: AxiosResponse<string>) => d.data)
}

export {getUserDetail, updateUserDetail, updateUserAvatar, getClickUpAuthUrl}
