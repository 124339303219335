/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { setUserTheme } from '../../../../app/modules/accounts/currentUserSlice'
import { getUserReport } from '../../../../app/modules/apps/companies/users-list/core/_requests'
import Swal from 'sweetalert2'
import { getClickUpAuthUrl } from '../../../../app/modules/accounts/core/_request'

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth()
  const dispatch = useAppDispatch()
  const currentUserInfo = useAppSelector((state:any) => state.currentUserMDL.data)
  const currentUserTheme = useAppSelector((state:any) => state.currentUserMDL.theme)

  const onSetTheme = (theme:any) => {
    localStorage.setItem('kt-react-theme-value', theme)
    dispatch(setUserTheme(theme))
    window.location.reload()
  }

  const [isDownloading, setDownloading] = useState(false)
  const printTaskList = async () => {
    if (!currentUserInfo.hasClickUpAccess) {
      let url = await getClickUpAuthUrl();
      window.location.href = url;
    } else {
      let result = await Swal.fire({
        title: 'Include subtasks?',
        text: 'Do you want to include subtasks in the report?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No'
      })
  
      if (!result.isDismissed) {
        setDownloading(true);
        try {
          await getUserReport(currentUserInfo.id, result.isConfirmed).then((res:any) => {
            if (res) {
              window.open(res, '_blank')
              Swal.fire({
                title: 'Download Ready',
                text: 'Downloading file...',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              setDownloading(false);
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Something went wrong downloading the task list, please try again',
                icon: 'error',
              })
            }
            setDownloading(false);
          })
        } catch (error) {
          setDownloading(false);
        }
      }
    }
  }

  useEffect(() => {
    const currTheme = localStorage.getItem('kt-react-theme-value')
    if(!currTheme) onSetTheme('light')
    dispatch(setUserTheme(currTheme))
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={currentUserInfo?.picUrl || toAbsoluteUrl('/media/avatars/blank.png')} />{' '}
          </div>

          <div className='d-flex flex-column ellipsis'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUserInfo?.firstname} {currentUserInfo?.lastname}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUserInfo?.email || 'No Email'} 
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={`/crafted/account/overview?user=${currentUserInfo?.id}`} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {currentUserInfo?.clickUpUserId && <>
        <div className='menu-item px-5'>
          <a onClick={() => printTaskList()} className='menu-link px-5' style={{pointerEvents: isDownloading ? 'none' : 'auto', color: isDownloading ? 'lightgray' : ''}}>
            {isDownloading ? "Downloading Tasks..." : "Print Task List"}
          </a>
        </div>
      </>}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <span className='menu-link px-5' onClick={() => onSetTheme(currentUserTheme === 'light' ? 'dark' : 'light')}>
          {currentUserTheme === 'light' ? 'Dark Mode' : 'Light Mode'}</span>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
